import React from 'react'
import { Breadcrumbs, Hero, Page, Projects as Pr } from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'projects.meta.title',
  description: 'projects.meta.descriptions',
}

const Projects = ({ pageContext }) => (
  <Page
    isHomepage
    locale={pageContext.locale}
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: buildLocalizedPath('/projects/', pageContext.locale), name: 'header.link.projects'},
      ]}
    />
    <Hero
      slim
      description="projects.hero.description"
      subtitle="projects.hero.subtitle"
      title="projects.hero.title"
    />
    <Pr />
  </Page>
)

export default Projects